
import OrganizationList from '@/components/organizations/OrganizationList.vue';
import Vue from 'vue';

export default Vue.extend({
  components: { OrganizationList },
  metaInfo: {
    title: 'Organiztion - '
  },
  name: 'Organiztion'
});
